import React from "react";
import { useForm } from "react-hook-form";
import { usePostTips } from "hooks/Tips/usePostTips";
import Loader from "components/loader";
import Layout from "components/layout";
import formCommonPostData from "utils/form-common-post-data";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";
import Seo from "components/seo";

export default function ShareTip() {
  const { status, mutateAsync } = usePostTips();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      language_fact: data.language_fact,
      email: data.email,
      publish_date: data.date,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      reset();
    });
  };

  return (
    <Layout>
      <Seo title="Share Translation Tips" slug="/translation-tips/sharetip" />
      <div className="w-full bg-share-facts bg-no-repeat bg-cover bg-center h-[300px]"></div>
      <div className="bg-[#1d1c1c] py-20 font-primary">
        <div className="w-full md:px-0 px-5 xl:w-6/12 lg:w-10/12 flex flex-col justify-center mx-auto text-white">
          <h5 className="text-[22px] font-[400]">Share Your Facts</h5>
          <h5 className="border-b border-white py-8 text-[16px]">
            Fill Out Your Facts Details
          </h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="lg:pb-0 pb-4 pt-8 flex flex-col md:flex-row md:justify-between mx-auto w-11/12">
              <h5 className="md:w-3/12 w-full text-sm font-light">Name</h5>
              <div className="md:flex-col flex-row md:w-9/12 mt-4 md:mt-0">
                <input
                  name="name"
                  type="text"
                  className="py-2  w-full px-2  font-light text-black"
                  placeholder="Name"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="text-red text-xs pt-2">{errorMessage}</span>
                )}
              </div>
            </div>
            <div className="lg:pb-0 pb-4 pt-8  flex flex-col md:flex-row md:justify-between mx-auto w-11/12  ">
              <h5 className="md:w-3/12 w-full text-sm font-light">Tip</h5>
              <div className="md:flex-col flex-row md:w-9/12 mt-4 md:mt-0">
                <textarea
                  rows="3"
                  cols="50"
                  name="language_fact"
                  type="text"
                  className="py-2 w-full px-2 text-black font-light"
                  placeholder="Add Tip"
                  {...register("language_fact", { required: true })}
                />
                {errors.language_fact && (
                  <span className="text-red text-xs pt-2">{errorMessage}</span>
                )}
              </div>
            </div>
            <div className="lg:pb-0 pb-4 pt-8  flex flex-col md:flex-row md:justify-between mx-auto w-11/12  ">
              <h5 className="md:w-3/12 w-full text-sm font-light">
                Publish Date
              </h5>
              <div className="md:flex-col flex-row md:w-9/12 mt-4 md:mt-0">
                <input
                  name="date"
                  type="text"
                  className="py-2 w-full px-2 text-black font-light"
                  placeholder="Date"
                  {...register("date")}
                />
              </div>
            </div>
            <div className="lg:pb-0 pb-4 pt-8 flex flex-col md:flex-row md:justify-between mx-auto w-11/12">
              <h5 className="md:w-3/12 w-full text-sm font-light">
                E-mail Address
              </h5>
              <div className="md:flex-col flex-row md:w-9/12 mt-4 md:mt-0">
                <input
                  name="email"
                  type="email"
                  className="py-2  w-full px-2 text-black font-light"
                  placeholder="Email"
                  {...register("email", {
                    required: true,
                    pattern: patternEmail,
                  })}
                />
                {errors.email && (
                  <span className="text-red text-xs pt-2">
                    {errorMessageEmail}
                  </span>
                )}
              </div>
            </div>
            {/* <div className="lg:pb-0 pb-4 pt-8  flex flex-col md:flex-row justify-between lg:justify-start mx-auto w-11/12 lg:relative">
              <h5 className="md:w-3/12 w-full text-sm font-light md:mr-[-2rem] mr-0 lg:mr-0">
                Captcha
              </h5>
              <div className="md:flex-col justify-start flex-row md:w-3/12 mt-4 md:mt-0">
                <input
                  name="title"
                  type="text"
                  className="py-2 w-32 px-2 text-black font-light"
                />
              </div>
              <div className="lg:absolute">
                <input
                  disabled
                  name="title"
                  type="text"
                  className="py-2 w-32 px-2 pl-6 text-black font-light lg:absolute lg:left-[20rem]"
                />
              </div>
            </div> */}
            <div className="mx-auto w-11/12">
              <input type="hidden" id="zc_gad" name="zc_gad" value="" />
              <button
                type="submit"
                className="bg-lightBlue rounded mt-[4rem] px-14 py-3 float-right"
              >
                {status === "loading" ? <Loader /> : "SHARE"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
